// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
// Vuetify
import { createVuetify } from 'vuetify'

const options = {
  date: {
    locale: {
      ru: 'ru-RU',
    },
  },
}
export default createVuetify(
    options
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
