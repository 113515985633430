import { Shipment } from '@/modules/shipment/types'
import { Organisations } from '@/modules/organisation/models/Organisation'

export namespace Order {
  export class Order {
    id?: number | null
    organisation!: Organisations.Organisation
    shipments: Array<Shipment.Shipment> = []
    created_at?: string
    updated_at?: string
  }

  export class Filters {
    pickup: PointContext = new PointContext()
    destination: PointContext = new PointContext()
  }

  export class PointContext {
    city_id?: number | null
    date?: string | null
  }
}