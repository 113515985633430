<template>
  <v-app-bar
    app
    dark
  >
    <v-layout>
      <v-app-bar-title>
        <v-btn
          to="/"
          variant="text"
        >
          {{ title }}
        </v-btn>
      </v-app-bar-title>

      <v-tooltip>
        <template v-slot:activator="{ props }">
          <v-btn
            outlined
            to="/search"
            v-bind="props"
            append-icon="mdi-briefcase-search-outline"
          >
            Поиск грузоперевозок
          </v-btn>
        </template>
        <span>Перейти к поиску грузоперевозок</span>
      </v-tooltip>

      <v-tooltip>
        <template v-slot:activator="{ props }">
          <v-btn
            outlined
            to="/order"
            v-bind="props"
            append-icon="mdi-truck-cargo-container"
          >
            Заказы
          </v-btn>
        </template>
        <span>Перейти к заказам</span>
      </v-tooltip>

      <v-tooltip
        v-if="accountStore.getAuth"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            to="/organisation"
            v-bind="props"
            append-icon="mdi-account-group"
          >
            Организации
          </v-btn>
        </template>
        <span>Перейти к организациям</span>
      </v-tooltip>

      <v-tooltip
        v-if="accountStore.getAuth"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            outlined
            v-bind="props"
            to="/account"
            append-icon="mdi-account"
          >
            Профиль
          </v-btn>
        </template>
        <span>Перейти в профиль</span>
      </v-tooltip>

      <v-tooltip
        v-if="!accountStore.getAuth"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            to="/login"
            v-bind="props"
            append-icon="mdi-account"
          >
            Вход/Регистрация
          </v-btn>
        </template>
        <span>Авторизоваться</span>
      </v-tooltip>
    </v-layout>
  </v-app-bar>
</template>

<script>
import { Vue, Component } from 'vue-facing-decorator'
import useAccountStore from '@/modules/account/store'

@Component({})
export default class HeaderFragment extends Vue {
  title = `${process.env.APP_TITLE}`
  accountStore = useAccountStore()
}
</script>

<style scoped>
</style>
