
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Order } from '@/modules/order/types'
import ShipmentFormPage from '@/modules/shipment/components/ShipmentFormPage.vue'
import { Shipment } from '@/modules/shipment/types'
import ShipmentIndexPage from '@/modules/shipment/components/ShipmentIndexPage.vue'
import useOrganisationStore from '@/modules/organisation/store'
import { OrderService } from '@/modules/order/services'
import { Messages } from '@/enums'
import OrderMessages = Messages.Order
import useOrderStore from '@/modules/order/store'

const orderService = new OrderService

@Component({
  components: { ShipmentIndexPage, ShipmentFormPage },
})
export default class OrderFormPage extends Vue {
  @Prop({ type: Object, default: {} })
  order!: Order.Order

  shipment!: Shipment.Shipment

  show: boolean = false
  valid: boolean = false
  organisationStore = useOrganisationStore()
  orderStore = useOrderStore()
  snackbar: boolean = false
  snackMessage: string = ''

  created() {
    if (this.organisationStore.getOrganisations.length === 1) {
      this.order.organisation = this.organisationStore.getOrganisations[0]
    }
    this.shipment = new Shipment.Shipment()
  }

  onAddShipment(model: Shipment.Shipment) {
    this.show = false
    this.order.shipments.push(model)
  }

  async submit() {
    const response = await orderService.create(this.order)
    if (response?.id) {
      this.orderStore.addOrder(this.order)
      this.order.shipments = []
      this.snackMessage = OrderMessages.ORDER_CREATED
      this.snackbar = true
    }
  }

  newShipment() {
    this.show = true
    this.shipment = new Shipment.Shipment()
  }
}
