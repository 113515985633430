
import { Options, Vue } from 'vue-class-component';
import HeaderFragment from "@/modules/layouts/components/HeaderFragment.vue";
import FooterFragment from "@/modules/layouts/components/FooterFragment.vue";

@Options({
  components: {
    FooterFragment,
    HeaderFragment,
  },
})
export default class App extends Vue {
  created() {
    document.title = process.env.APP_TITLE
  }
}
