
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { Order } from '@/modules/order/types'
import { ShipmentType, ShipmentTypeLabels } from '@/modules/shipment/enums'


@Component({})
export default class OrderCard extends Vue {
  @Prop({ type: Object })
  model!: Order.Order

  getShipmentTypeLabel(type: ShipmentType) {
    return ShipmentTypeLabels[type]
  }
}
