import axios from 'axios'
import useAccountStore from '@/modules/account/store'

const axiosInstance = axios.create({
    baseURL: process.env.API_URL,
})

axiosInstance.interceptors.request.use((config) => {
    const accountStore = useAccountStore()
    config.headers.Authorization = `Bearer ${accountStore.getToken}`
    config.headers.Accept = "application/json"
    return config
})

axiosInstance.interceptors.response.use(response => {
    return response
}, error => {
    const accountStore = useAccountStore()

    if (!error.response) {
        // return Promise.reject(error)
    }

    if (error.response.status === 401) {
        accountStore.removeAuth()
        window.location.replace('/login')
    }

    if (error.response.status === 400 || error.response.status === 500) {
        console.log(error.response?.message)
        return
    }

    // return Promise.reject(error)
});

export default axiosInstance
