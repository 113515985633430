import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'vuetify/styles'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import router from '@/plugins/router'
import moment from 'moment'

const pinia = createPinia()

pinia.use(createPersistedState({
    auto: true,
}))

const app = createApp(App)
const date = (value: any) => {
    return value ? moment(value).utcOffset(12).format('DD-MM-YYYY hh:mm') + ` (Мск)` : ''
}

app.config.globalProperties.$filters = {
    date,
}

app.use(pinia)
app.use(router)
app.use(vuetify)
app.use(mdiVue, {
    icons: mdijs,
})
app.mount('#app')
