<template>
  <v-sheet width="500" class="mx-auto">
    <div class="text-h4 pa-5">Авторизация</div>

    <v-form
        v-model="valid"
        @submit.prevent="submit"
    >
      <v-text-field
          v-model="state.email"
          label="Логин"
          :rules="[
              v => !!v || 'Обязательное поле',
          ]"
          required="true"
      ></v-text-field>

      <v-text-field
          v-model="state.password"
          type="password"
          label="Пароль"
          :rules="[
              v => !!v || 'Обязательное поле',
          ]"
          required
      ></v-text-field>

      <v-container>
        <v-btn
            color="grey-lighten-4"
            class="me-4"
            type="submit"
        >
          Авторизоваться
        </v-btn>
      </v-container>
      <v-container>
        <v-btn
            class="me-4"
            to="restore"
        >
          Восстановить пароль
        </v-btn>
        <v-btn
            to="registration"
            class="me-4"
        >
          Регистрация
        </v-btn>
      </v-container>
    </v-form>
    <v-snackbar
        location="right top"
        v-model="snackbar"
        color="orange-darken-2"
        :timeout="2000"
    >
      {{ error }}
    </v-snackbar>
  </v-sheet>
</template>
<script>
import { Component, Vue } from 'vue-facing-decorator'
import useAccountStore from '@/modules/account/store'
import { Messages } from '@/enums'

@Component
export default class LoginPage extends Vue {
  valid = false
  state = {}
  loading = false
  accountStore = useAccountStore()
  error = Messages.Account.WRONG_CREDENTIALS
  snackbar = false

  async submit () {
    if (!this.valid) {
      return
    }
    this.loading = true
    const data = await this.accountStore.login(this.state.email, this.state.password)
    if (data && data.auth) {
      this.$router.push('/')
    } else {
      this.snackbar = true
    }
    this.loading = false
  }
}
</script>

<style scoped>

</style>