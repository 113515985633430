export enum ShipmentType {
    general = 'general', // штучный
    bulk = 'bulk', // насыпной
    powdered = 'powdered', // порошкообразный
    liquid = 'liquid', // жидкий
    refrigerated = 'refrigerated', // скоропортящийся
    containerized = 'containerized', // контейнер
    dangerous = 'dangerous', // опасный
    oversized = 'oversized', // негабаритный
    rolled = 'rolled', // катно-бочковой
    long = 'long', // длинномерный
    heavy = 'heavy', // тяжеловесный
    livestock = 'livestock', // животные
    automobile = 'automobile', // автомобили
    hazard = 'hazard', // антисанитарные
}

export const ShipmentTypeLabels = {
    [ShipmentType.general]: 'Штучный',
    [ShipmentType.bulk]: 'Насыпной',
    [ShipmentType.powdered]: 'Порошкообразный',
    [ShipmentType.liquid]: 'Жидкий',
    [ShipmentType.refrigerated]: 'Скоропортящийся',
    [ShipmentType.containerized]: 'Контейнер',
    [ShipmentType.dangerous]: 'Опасный',
    [ShipmentType.oversized]: 'Негабаритный',
    [ShipmentType.rolled]: 'Катно-бочковой',
    [ShipmentType.long]: 'Длинномерный',
    [ShipmentType.heavy]: 'Тяжеловесный',
    [ShipmentType.livestock]: 'Животные',
    [ShipmentType.automobile]: 'Автомобили',
    [ShipmentType.hazard]: 'Антисанитарные'
}

export function is_iterable(type: ShipmentType) {
    return [ShipmentType.general, ShipmentType.automobile].includes(type);
}