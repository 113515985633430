
import { Component, Emit, Prop, Ref, Vue } from 'vue-facing-decorator'
import GeoSearch from '@/modules/geo/components/GeoSearch.vue'
import useOrganisationStore from '@/modules/organisation/store'
import { Shipment } from '@/modules/shipment/types'
import { is_iterable, ShipmentType, ShipmentTypeLabels } from '@/modules/shipment/enums'
import { VForm } from 'vuetify/components/VForm'

@Component({
  components: { GeoSearch }
})
export default class ShipmentFormPage extends Vue {
  @Ref()
  readonly form!: VForm;

  @Prop({ type: Shipment.Shipment, default: {} })
  model!: Shipment.Shipment
  organisationStore = useOrganisationStore()
  geo = null
  show: boolean = false
  valid: boolean = false
  types!: any

  created() {
    this.types = Object.keys(ShipmentTypeLabels).map((key) => {
      return { name: key, label: ShipmentTypeLabels[key as keyof typeof ShipmentTypeLabels] }
    })
  }

  submit() {
    console.log(this.model)
    if (!this.valid) {
      return
    }
    this.addShipment()
  }

  isIterable(type: ShipmentType) {
    return is_iterable(type)
  }

  @Emit('addShipmentEvent')
  addShipment() {
    if (!this.valid) {
      return
    }

    return this.model
  }
}
