
import { Vue, Component } from 'vue-facing-decorator'
import { Messages } from '@/enums'

@Component({})
export default class FooterFragment extends Vue {
  snackbar: boolean = false
  snackMessage: string = Messages.Common.DATA_UPDATED
  title = `${process.env.APP_TITLE}`

  links = [
    {
      'link': 'about',
      'title': 'О компании',
    },
    {
      'link': 'privacy',
      'title': 'Политика конфиденциальности',
    },
  ];
}
