import axios from '@/plugins/httpClient'
import { Account } from '@/modules/account/types'

export class AccountService {
    async login(login: string, password: string) {
        return axios.post('auth/login', {
            email: login,
            password
        }).then(response => {
            if (response.status === 200) {
                return response.data
            }
        }).catch(error => {
            console.log(error)
        })
    }

    async registration(user: Account.Registration) {
        return axios.post('user', user)
            .then(response => {
                return response.data
            }).catch(error => {
                return error.response.data
            })
    }

    async logout() {
        return axios.post('auth/logout')
            .then(response => {
                if (response.status === 200) {
                    return response.data
                }
            }).catch(error => {
                console.log(error)
            })
    }

    async restore(email: string) {
        return axios.post('password/request', {email})
            .then(response => {
                if (response.status === 200) {
                    return response.data
                }
            }).catch(error => {
                console.log(error)
            })
    }

    async reset(account: Account.Recovery) {
        return axios.post('password/reset', account)
            .then(response => {
                if (response.status === 200) {
                    return response.data
                }
            }).catch(error => {
                console.log(error)
            })
    }

    async me() {
        return axios.get('user')
            .then(response => {
                if (response.status === 200) {
                    return response.data
                }
            })
    }

    async update(account: Account.User) {
        return axios.patch('user', account)
            .then(response => {
                if (response.status === 200) {
                    return response.data
                }
            }).catch(error => {
                console.log(error)
            })
    }
}
