import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    title: 'Заказ №' + _ctx.model.id
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.shipments, (shipment) => {
            return (_openBlock(), _createBlock(_component_v_list, {
              align: "left",
              key: shipment.id
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, "Состав: " + _toDisplayString(shipment.title), 1),
                _createElementVNode("div", null, "Тип товара: " + _toDisplayString(_ctx.getShipmentTypeLabel(shipment.type)), 1),
                _createElementVNode("div", null, "Откуда: " + _toDisplayString(shipment.pickup?.value || 'Не указано'), 1),
                _createElementVNode("div", null, "Дата отгрузки: " + _toDisplayString(shipment.pickup_date), 1),
                _createElementVNode("div", null, "Куда: " + _toDisplayString(shipment.destination?.value || 'Не указано'), 1),
                _createElementVNode("div", null, "Дата доставки: " + _toDisplayString(shipment.destination_date), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}