<template>
  <h1>
    Страница не найдена
  </h1>
</template>
<script>
import { Component, Vue } from 'vue-facing-decorator'

@Component
export default class NotFoundPage extends Vue {

}
</script>

<style scoped>

</style>