import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!

  return (_openBlock(), _createBlock(_component_v_autocomplete, _mergeProps(_ctx.$attrs, {
    items: _ctx.items,
    "item-title": "value",
    "item-value": "(value, latitude)",
    "onUpdate:search": _ctx.search,
    label: _ctx.label,
    "hide-no-data": ""
  }), null, 16, ["items", "onUpdate:search", "label"]))
}