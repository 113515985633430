<template>
  <v-sheet width="500" class="mx-auto">
    <div class="text-h4 pa-5">Восстановление пароля</div>

    <v-form
      @submit.prevent="submit"
      v-model="valid"
    >
      <v-text-field
          v-model="state.email"
          label="Email"
          required
          :rules="[
              v => !!v || 'Обязательное поле',
          ]"
      ></v-text-field>

      <v-container>
        <v-btn
            class="me-4"
            color="grey-lighten-4"
            type="submit"
        >
          Сбросить пароль
        </v-btn>
      </v-container>
      <v-container>
        <v-btn
            @click="this.$router.push('login')"
            class="me-4"
        >
          Авторизация
        </v-btn>
        <v-btn
            @click="this.$router.push('registration')"
            class="me-4"
        >
          Регистрация
        </v-btn>
      </v-container>
    </v-form>
    <v-snackbar
        location="right top"
        v-model="snackbar"
        color="orange-darken-2"
        :timeout="2000"
    >
      {{ snackMessage }}
    </v-snackbar>
  </v-sheet>
</template>
<script>
import { Component, Vue } from 'vue-facing-decorator'
import useAccountStore from '@/modules/account/store'
import { Messages } from '@/enums'

@Component
export default class LoginPage extends Vue {
  valid = false
  state = {}
  loading = false
  accountStore = useAccountStore()
  snackbar = false
  snackMessage = ''

  async submit () {
    if (!this.valid) {
      return;
    }
    this.loading = true
    const data = await this.accountStore.restore(this.state.email)
    if (data && data.response === 'passwords.sent') {
      this.snackMessage = Messages.Account.PASSWORDS_SEND
    } else {
      this.snackMessage = Messages.Errors.ERROR_SEND_MESSAGE
    }
    this.snackbar = true
    this.loading = false
  }
}
</script>

<style scoped>

</style>