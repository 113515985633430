import { ShipmentType } from '@/modules/shipment/enums'
import { Geo } from '@/modules/geo/types'

export namespace Shipment {
    export class Shipment {
        id?: number | null
        weight: number | undefined
        length: number | undefined
        width: number | undefined
        height: number | undefined
        pickup!: Geo.Data
        destination!: Geo.Data
        pickup_date: string | undefined
        destination_date: string | undefined
        amount: number = 1
        type: ShipmentType = ShipmentType.general
        title: string | null = ''
        description: string | null = ''
    }
}