
import { Vue, Component, Prop } from 'vue-facing-decorator'
import { GeoService } from '@/modules/geo/services'

const geoService = new GeoService()

@Component({})
export default class GeoSearch extends Vue {
  items!: Object[]
  loading: boolean = false

  @Prop({ type: String })
  label!: 'Адрес'

  search(search: any) {
    if (search.length <= 3) {
      return
    }
    if (this.loading) {
      return
    }
    this.loading = true

    geoService.search(search).then(response => { this.items = response })
    this.loading = false
  }
}
