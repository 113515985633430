
import { Component, Vue } from 'vue-facing-decorator'
import useAccountStore from "@/modules/account/store"
import { Messages } from "@/enums"
import { Account } from '@/modules/account/types'

@Component({})
export default class RegistrationPage extends Vue {
  account = {} as Account.Registration
  loading: boolean = false
  snackbar: boolean = false
  valid: boolean = false
  snackMessage: string = ''
  accountStore = useAccountStore()

  async submit () {
    if (!this.valid) {
      return
    }
    this.loading = true
    const data = await this.accountStore.registration(this.account)
    if (!data) {
      this.snackMessage = Messages.Errors.REGISTRATION
      this.snackbar = true
      return
    }
    if (data.auth) {
      this.snackMessage = Messages.Account.REGISTRATION_SUCCESS
      return this.$router.push('/')
    }
    this.loading = false
  }
}
