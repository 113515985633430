import { defineStore } from 'pinia'
import { Organisations } from '@/modules/organisation/models/Organisation'
import { OrganisationService } from '@/modules/organisation/services'

const service = new OrganisationService()

const useOrganisationStore = defineStore('organisationStore', {
    state: () => ({
        organisations: [] as Organisations.Organisation[],
        organisation: {} as Organisations.Organisation || null,
    }),
    getters: {
        getOrganisations: (state) => state.organisations,
    },
    actions: {
        async fetchOrganisations() {
            this.organisations = await service.getOrganisations()
        },
    },
})

export default useOrganisationStore
