import { defineStore } from 'pinia'
import { Order } from '@/modules/order/types'
import { OrderService } from '@/modules/order/services'

const orderService = new OrderService()

const useOrderStore = defineStore('orderStore', {
    state: () => ({
        orders: [] as Order.Order[],
    }),
    getters: {
        getOrders: (state) => state.orders,
    },
    actions: {
        addOrder(order: Order.Order) {
            this.orders.push(order)
        },
        async fetchSearchOrders(filters: Order.Filters): Promise<Order.Order[]> {
            return this.orders = await orderService.searchOrders(filters)
        },
        async fetchUserOrders(): Promise<Order.Order[]> {
            this.orders = await orderService.getUserOrders()
            return this.orders
        },
    },
})

export default useOrderStore
