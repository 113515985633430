
import { Component, Vue } from 'vue-facing-decorator'
import { Order } from '@/modules/order/types'
import OrderFormPage from '@/modules/order/components/OrderFormPage.vue'
import useOrderStore from '@/modules/order/store'

@Component({
  components: { OrderFormPage }
})
export default class OrderIndexPage extends Vue {
  order!: Order.Order
  orders = [] as Order.Order[]
  orderStore = useOrderStore()
  show: boolean = false

  async created() {
    await this.orderStore.fetchUserOrders()
    this.orders = this.orderStore.orders || []
  }

  addOrder() {
    this.show = !this.show
    this.order = new Order.Order()
  }
}
