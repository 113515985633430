import CommonRoutes from '@/modules/pages/routes'
import AccountRoutes from '@/modules/account/routes'
import OrganisationRoutes from '@/modules/organisation/routes'
import OfferRoutes from '@/modules/order/routes'
import SearchRoutes from '@/modules/search/routes'
import NotFoundPage from '@/modules/pages/components/NotFoundPage.vue'

export default [
    ...CommonRoutes,
    ...AccountRoutes,
    ...OrganisationRoutes,
    ...OfferRoutes,
    ...SearchRoutes,
    {
        path: '/:pathMatch(.*)*',
        component: NotFoundPage,
    },
];