import axios from '@/plugins/httpClient'
import { Order } from '@/modules/order/types'
import Filters = Order.Filters

export class OrderService {
    async create(order: Order.Order): Promise<Order.Order> {
        return axios.post('order', order)
            .then(response => {
                if (response.status === 200) {
                    return response.data
                }
            }).catch(error => {
                console.log(error)
            })
    }

    async searchOrders(filters: Filters): Promise<Order.Order[]> {
      const params = {
        pickup: {
          city_id: filters.pickup.city_id,
        },
        destination: {
          city_id: filters.destination?.city_id
        }
      }
      const { data } = await axios.get('search', { params: params })
        .then(response => {
            if (response.status === 200) {
                return response.data
            }
        }).catch(error => {
            console.log(error)
        })
        return data?.data || []
    }

    async getUserOrders(): Promise<Order.Order[]> {
        const { data } = await axios.get('user/orders')
            .then(response => {
                if (response.status === 200) {
                    return response.data
                }
            }).catch(error => {
                console.log(error)
            })
        return data?.data || []
    }
}