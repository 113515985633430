export namespace Messages {
    export enum Account {
        REGISTRATION_SUCCESS = 'Успешная регистрация',
        WRONG_CREDENTIALS = 'Неправильная связка логин и пароль',
        PASSWORDS_SEND = 'На электронную почту отправлено письмо',
        PASSWORD_CHANGED = 'Пароль изменен'
    }

    export enum Order {
        ORDER_CREATED = 'Заказ создан'
    }

    export enum Errors {
        REGISTRATION = 'Ошибка регистрации',
        RECOVERY = 'Ошибка смены пароля',
        ERROR_SEND_MESSAGE = 'Ошибка отправки сообщения',
    }

    export enum Common {
        DATA_UPDATED = 'Данные обновлены',
        ERROR_UPDATE = 'Ошибка обновления данных'
    }
}