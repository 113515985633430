import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_GeoSearch = _resolveComponent("GeoSearch")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, {
        "fast-fail": "",
        modelValue: _ctx.valid,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.valid) = $event)),
        onSubmit: _withModifiers(_ctx.submit, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.model.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.title) = $event)),
            label: "Наименование груза",
            rules: [
          v => !!v || 'Обязательное поле',
        ],
            required: "true"
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_v_select, {
            modelValue: _ctx.model.type,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.type) = $event)),
            label: "Тип груза",
            items: _ctx.types,
            "item-title": "label",
            "item-value": "name"
          }, null, 8, ["modelValue", "items"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.model.weight,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.weight) = $event)),
            label: "Вес (кг)",
            rules: [
          v => !!v || 'Обязательное поле',
        ],
            required: "true"
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.model.width,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.width) = $event)),
            label: "Ширина (см)",
            rules: [
          v => !!v || 'Обязательное поле',
        ],
            required: "true"
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.model.height,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.height) = $event)),
            label: "Высота (см)",
            rules: [
          v => !!v || 'Обязательное поле',
        ],
            required: "true"
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.model.length,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.length) = $event)),
            label: "Глубина (см)",
            rules: [
          v => !!v || 'Обязательное поле',
        ],
            required: "true"
          }, null, 8, ["modelValue", "rules"]),
          (_ctx.isIterable(_ctx.model.type))
            ? (_openBlock(), _createBlock(_component_v_text_field, {
                key: 0,
                modelValue: _ctx.model.amount,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.amount) = $event)),
                label: "Количество (шт)",
                rules: [
          v => !!v || 'Обязательное поле',
        ],
                required: "true"
              }, null, 8, ["modelValue", "rules"]))
            : _createCommentVNode("", true),
          _createVNode(_component_GeoSearch, {
            modelValue: _ctx.model.pickup,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.pickup) = $event)),
            label: "Откуда"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_GeoSearch, {
            modelValue: _ctx.model.destination,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.destination) = $event)),
            label: "Куда"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_textarea, {
            modelValue: _ctx.model.description,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.description) = $event)),
            label: "Дополнительная информация",
            rows: "1",
            "auto-grow": ""
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_btn, {
            type: "submit",
            class: "mt-4"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Сохранить ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onSubmit"])
    ]),
    _: 1
  }))
}