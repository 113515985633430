import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createBlock(_component_v_footer, {
    absolute: "",
    class: "pa-16"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (item) => {
            return (_openBlock(), _createBlock(_component_v_btn, {
              key: item,
              variant: "text",
              class: "mx-2",
              rounded: "xl",
              href: item.link
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1032, ["href"]))
          }), 128)),
          _createVNode(_component_v_col, {
            class: "text-center mt-4",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.title), 1),
              _createElementVNode("div", null, _toDisplayString(new Date().getFullYear()), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}