
import { Component, Vue } from 'vue-facing-decorator';
import { GeoService } from '@/modules/geo/services'
import { Geo } from '@/modules/geo/types'

const geoService = new GeoService()

@Component({})
export default class CitySelect extends Vue {
  items = [] as Geo.City[]
  loading = false

  search(search: any) {
    if (search.length <= 3) {
      return
    }
    if (this.loading) {
      return
    }
    this.loading = true

    geoService.getCities(search).then(response => { this.items = response })
    this.loading = false
  }
}
