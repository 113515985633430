import HomePage from "@/modules/pages/components/HomePage.vue";
import PrivacyPage from '@/modules/pages/components/PrivacyPage.vue'

export default [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: '/privacy',
        component: PrivacyPage,
    },
]
