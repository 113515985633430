
import {Vue, Prop, Component} from 'vue-facing-decorator'
import { Account } from '@/modules/account/types'
import { AccountService } from '@/modules/account/services'
import { Messages } from '@/enums'
import useAccountStore from '@/modules/account/store'

const service = new AccountService()

@Component({})
export default class PasswordRecoveryPage extends Vue {
  @Prop({ type: Object, default: {} })
  account!: Account.Recovery
  accountStore = useAccountStore()
  valid: boolean = false
  loading: boolean = false
  snackMessage: string = ''
  snackbar: boolean = false

  async submit() {
    this.account.email = this.$route.query!.email?.toString() || null;
    this.account.token = this.$route.query!.token?.toString() || null;
    if (!this.valid) {
      return
    }
    this.loading = true
    const data = await service.reset(this.account);

    if (!data) {
      this.snackMessage = Messages.Errors.RECOVERY
      this.snackbar = true
      return
    }
    if (data.response === 'passwords.reset') {
      if (await this.login(this.account)) {
        this.$router.push('/')
      }
      this.snackMessage = Messages.Account.PASSWORD_CHANGED
    }
    this.loading = false
  }

  async login(account: Account.Recovery) {
    if (account.email != null) {
      return await this.accountStore.login(account.email, account.password)
    }
  }
}
