import axios from '@/plugins/httpClient'
import { Organisations } from '@/modules/organisation/models/Organisation'

export class OrganisationService {
    public basePath = 'organisation'

    async getOrganisations(): Promise<Organisations.Organisation[]> {
        const { data } = await axios.get(this.basePath)
            .then(response => {
                return response
            })
        return data?.data || [];
    }

    createOrganisation(organisation: Organisations.Organisation) {
        return axios.post(this.basePath, organisation)
            .then(response => {
                if (response && response.status === 200) {
                    return response.data
                }
                return response
            })
    }

    updateOrganisation(organisation: Organisations.Organisation) {
        return axios.patch(this.basePath + `/${organisation.id}`, organisation)
            .then(response => {
                if (response && response.status === 200) {
                    return response.data
                }
                return response
            })
    }
}
