
import { Component, Ref, Prop, Vue } from 'vue-facing-decorator'
import { OrganisationService } from '@/modules/organisation/services'
import { Messages } from '@/enums'
import GeoSearch from '@/modules/geo/components/GeoSearch.vue'
import { VForm } from 'vuetify/components/VForm'
import { Organisations } from '@/modules/organisation/models/Organisation'

const service = new OrganisationService()

@Component({
  components: { GeoSearch }
})
export default class OrganisationFormPage extends Vue {
  @Ref()
  readonly form!: VForm;

  @Prop({ type: Object })
  model!: Organisations.Organisation

  valid: boolean = false
  loading: boolean = false
  snackbar: boolean = false
  snackMessage: string = Messages.Common.DATA_UPDATED

  async submit(event: any) {
    event.preventDefault()
    if (!this.valid) {
      return
    }
    let data = {}

    try {
      if (!this.model.id) {
        data = await service.createOrganisation(this.model)
      } else {
        data = await service.updateOrganisation(this.model)
      }
    } catch (e: any) {
      console.log(e)
      this.snackMessage = e.response?.data.message || Messages.Common.ERROR_UPDATE
      this.snackbar = true
      return
    }

    if (data) {
      this.snackbar = true
    }
  }
}
