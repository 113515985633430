
import { Component, Vue } from 'vue-facing-decorator'
import ShipmentSearch from '@/modules/shipment/shared/ShipmentSearch.vue'

@Component({
  components: { ShipmentSearch }
})
export default class SearchPage extends Vue {

}
