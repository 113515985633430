import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-md-h5 text-lg-h6 pa-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.order.shipments.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Список отправлений: "))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, { class: "d-flex align-center flex-column" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.shipments, (shipment, index) => {
          return (_openBlock(), _createBlock(_component_v_row, {
            key: shipment.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { md: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, { width: "400" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_layout, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_spacer),
                              _createTextVNode(" " + _toDisplayString(shipment.title || 'Не данных') + " ", 1),
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_btn, {
                                flat: "",
                                onClick: ($event: any) => (_ctx.remove(index)),
                                icon: "mdi-close"
                              }, null, 8, ["onClick"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_divider)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, "Откуда: " + _toDisplayString(shipment.pickup.value), 1),
                          _createElementVNode("div", null, "Куда: " + _toDisplayString(shipment.destination.value), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}