
import { Component, Vue } from 'vue-facing-decorator'
import OrganisationFormPage from '@/modules/organisation/components/OrganisationFormPage.vue'
import useOrganisationStore from '@/modules/organisation/store'
import { Organisations } from "@/modules/organisation/models/Organisation";

@Component({
  components: { OrganisationFormPage }
})
export default class OrganisationIndexPage extends Vue {
  show: boolean = false
  organisationStore = useOrganisationStore()
  organisations = [] as Organisations.Organisation[]
  organisation!: Organisations.Organisation
  loading = true

  async mounted() {
    await this.organisationStore.fetchOrganisations()
    this.organisations = this.organisationStore.getOrganisations
    this.loading = false
  }

  async add() {
    this.organisation = new Organisations.Organisation()
    this.organisations = this.organisationStore.getOrganisations
    this.show = true
  }

  edit(organisation: Organisations.Organisation) {
    this.organisation = organisation
    this.show = true
  }

  goIndex() {
    this.show = false
    this.$router.push('organisation')
  }
}
