
import { Component, Ref, Vue } from 'vue-facing-decorator'
import useAccountStore from '@/modules/account/store'
import { VForm } from 'vuetify/components/VForm'
import { AccountService } from '@/modules/account/services'
import { Messages } from '@/enums'

const accountService = new AccountService()

@Component
export default class AccountIndexPage extends Vue {
  @Ref()
  readonly form!: VForm;

  accountStore = useAccountStore()
  user = this.accountStore.getUser
  valid = false
  loading = true
  snackbar: boolean = false
  snackMessage: string = Messages.Common.DATA_UPDATED

  async created() {
    this.user = await this.accountStore.me()
    this.loading = false
  }

  async submit(event: any) {
    event.preventDefault()
    const data = await accountService.update(this.user)

    if (data) {
      this.snackbar = true
    }
  }

  logout() {
    this.accountStore.logout()
    this.$router.push('/')
  }
}
