import { defineStore } from 'pinia'
import { AccountService } from '@/modules/account/services'
import { Account } from '@/modules/account/types'

const service = new AccountService()

const useAccountStore = defineStore('accountStore', {
    state: () => ({
        auth: false,
        access_token: '',
        user: {} as Account.User,
    }),
    getters: {
        getAuth: (state) => !!state.access_token,
        getToken: (state) => state.access_token,
        getUser: (state) => state.user,
    },
    actions: {
        async login(username: string, password: string) {
            this.auth = false
            const data = await service.login(username, password)

            if (data && data.access_token) {
                this.access_token = data.access_token
                this.auth = true

                return this.$state
            }
        },
        async registration(user: Account.Registration) {
            return await service.registration(user)
        },
        async logout() {
            await service.logout()
            this.removeAuth()
        },
        async restore(email: string) {
            return await service.restore(email)
        },
        removeAuth() {
            this.auth = false
            this.access_token = ''
        },
        async me(): Promise<Account.User> {
            return this.user = await service.me()
        }
    },
})

export default useAccountStore
