import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_GeoSearch = _resolveComponent("GeoSearch")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_sheet, {
      width: "500",
      class: "mx-auto"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_form, {
          modelValue: _ctx.valid,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.valid) = $event)),
          ref: "form",
          "validate-on": "input"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.model.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.title) = $event)),
              label: "Название",
              rules: [
          v => !!v || 'Обязательное поле',
        ],
              required: ""
            }, null, 8, ["modelValue", "rules"]),
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.model.inn,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.inn) = $event)),
              label: "ИНН",
              size: 10,
              rules: [
          v => !!v || 'Обязательное поле',
          v => v.length === 10 || 'Ожидается строка длинной 10 символов',
        ],
              required: ""
            }, null, 8, ["modelValue", "rules"]),
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.model.kpp,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.kpp) = $event)),
              label: "КПП",
              rules: [
          v => !!v || 'Обязательное поле',
          v => v.length === 9 || 'Ожидается строка длинной 9 символов',
        ],
              required: ""
            }, null, 8, ["modelValue", "rules"]),
            _createVNode(_component_GeoSearch, {
              modelValue: _ctx.model.address,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.address) = $event)),
              label: "Адрес",
              rules: [
          v => !!v || 'Обязательное поле',
        ],
              required: ""
            }, null, 8, ["modelValue", "rules"]),
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.model.phone,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.phone) = $event)),
              label: "Номер телефона",
              rules: [
          v => !!v || 'Обязательное поле',
        ],
              required: ""
            }, null, 8, ["modelValue", "rules"]),
            _createVNode(_component_v_btn, {
              loading: _ctx.loading,
              onClick: _ctx.submit,
              color: "grey-lighten-4",
              class: "me-4",
              type: "submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Сохранить ")
              ]),
              _: 1
            }, 8, ["loading", "onClick"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      location: "right top",
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.snackbar) = $event)),
      color: "orange-darken-2",
      timeout: 2000
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.snackMessage), 1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}