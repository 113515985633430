
import { Component, Vue, Watch } from 'vue-facing-decorator'
import { Order } from '@/modules/order/types'
import OrderFormPage from '@/modules/order/components/OrderFormPage.vue'
import useOrderStore from '@/modules/order/store'
import OrderCard from '@/modules/order/shared/OrderCard.vue'
import CitySelect from '@/modules/geo/shared/CitySelect.vue'

@Component({
  components: { CitySelect, OrderCard, OrderFormPage }
})
export default class ShipmentSearch extends Vue {
  order!: Order.Order
  filters!: Order.Filters
  orders = [] as Order.Order[]
  orderStore = useOrderStore()
  show: boolean = false
  apply: boolean = false

  @Watch('filters.pickup.city_id')
  onPickupCityChanged() {
    this.apply = false
  }

  @Watch('filters.destination.city_id')
  onDestinationCityChanged() {
    this.apply = false
  }

  created() {
    this.filters = new Order.Filters()
  }

  async search() {
    await this.orderStore.fetchSearchOrders(this.filters)
    this.orders = this.orderStore.getOrders
    this.apply = true
  }

  addOrder() {
    this.show = !this.show
    this.order = new Order.Order()
  }
}
