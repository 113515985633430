<template>
  <div>
    <h1>Система грузоперевозок</h1>
    <h3>Поиск грузоперевозок</h3>
    <JumbotronComponent/>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-facing-decorator'
import JumbotronComponent from "@/modules/layouts/components/JumbotronComponent.vue";

@Component({
  components: {JumbotronComponent}
})
export default class HomePage extends Vue {

}
</script>

<style scoped>

</style>