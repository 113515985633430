import axios from '@/plugins/httpClient'
import { Geo } from '@/modules/geo/types'

/**
 * Сервис работа с гео данными
 */
export class GeoService {
    public basePath = 'geo'

    async search(search: string): Promise<Geo.Data[]> {
        const { data } = await axios.get(this.basePath + '/search', { params: { search } })
        return data.data
    }

    async getCities(search: string): Promise<Geo.City[]> {
        const { data } = await axios.get(this.basePath + '/city', { params: { search } })
            .then(response => {
                return response
            })
        return data?.cities || [];
    }
}
