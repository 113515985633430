import LoginPage from "@/modules/account/components/LoginPage.vue";
import AccountIndexPage from "@/modules/account/components/AccountIndexPage.vue";
import RegistrationPage from "@/modules/account/components/RegistrationPage.vue";
import RestorePage from "@/modules/account/components/RestorePage.vue";
import PasswordRecoveryPage from "@/modules/account/components/PasswordRecoveryPage.vue";

export default [
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/account',
        component: AccountIndexPage,
    },
    {
        path: '/registration',
        component: RegistrationPage,
    },
    {
        path: '/restore',
        component: RestorePage,
    },
    {
        path: '/password',
        component: PasswordRecoveryPage,
    },
]
