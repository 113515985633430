import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/routes'
import useAccountStore from '@/modules/account/store'

const router = createRouter({
    routes,
    history: createWebHistory(),
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/', '/login', '/registration', '/restore', '/password', '/privacy'];
    const authRequired = !publicPages.includes(to.path);
    const accountStore = useAccountStore()
    const loggedIn = accountStore.getAuth

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});
export default router