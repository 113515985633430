
import { Component, Prop, Vue } from 'vue-facing-decorator'
import ShipmentFormPage from '@/modules/shipment/components/ShipmentFormPage.vue'
import { Order } from '@/modules/order/types'

@Component({
  components: { ShipmentFormPage }
})
export default class ShipmentIndexPage extends Vue {
  @Prop({ type: Order.Order })
  order!: Order.Order

  show: boolean = false

  remove(index: number) {
    console.log(index)
    this.order.shipments.splice(index, 1)
  }
}
