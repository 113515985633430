<template>
  <v-container>
    <v-row justify="space-around">
      <v-col
        v-for="elevation in elevations"
        :key="elevation"
        cols="12"
        md="4"
      >
        <v-sheet
          class="pa-12"
          border="md"
        >
          <div>{{ elevation.content }}</div>
          <v-btn
            :to="elevation.to"
          >
            {{ elevation.name }}
          </v-btn>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Component, Vue } from 'vue-facing-decorator'

@Component
export default class JumbotronComponent extends Vue {
  elevations = [
    {
      content: 'Для грузовладельцев',
      to: 'order',
      name: 'Сделать заказ',
    },
    {
      content: 'Для водителей',
      to: 'search',
      name: 'Поиск грузоперевозок',
    },
  ]

}
</script>

<style scoped>

</style>